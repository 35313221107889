"use client"
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {softWhite, text, white} from '@/styles/globalStyleVars';
import Button from "@/components/common/ui/Button";
import ImageSocialBox from "@/components/common/ImageSocialBox";
import axios from "axios";
import TextInput from "@/components/common/ui/forms/TextInput";
import useContainerOffset from "@/components/hooks/useContainerOffset";
import {errorToast, successToast} from '@/components/hooks/toastNotifications';
import {toast} from 'sonner'

const ContactForm = ({padding, data, id, pt, margin, showSetting}) => {

    const [selectResponseData, setSelectResponseData] = useState(null);
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});
    const {errors, isSubmitSuccessful} = formState;
    const offset = useContainerOffset('.container');
    const [isSubmitting, setIsSubmitting] = useState(true);



    const onSubmit = async (formData) => {

        setIsSubmitting(true); // Show spinner
        try {
            const data = new FormData();
            data.append('name', formData.name);
            data.append('email', formData.email);
            data.append('phone', formData.phone);
            data.append('qualification', formData.qualification);
            data.append('english', formData.english);
            data.append('prefered_program', formData.prefered_program);
            data.append('target_university', formData.target_university);
            data.append('intended_intake', formData.intended_intake);
            data.append('form_id', 'get-in-touch');

            const response = await axios.post('https://cms.maximuseducation.com.au/api/post-req-data/form-submit', data);
            setSelectResponseData(response);

            if (response?.data?.result === 'success') {
                // successToast(response?.data?.message);
                setSelectResponseData(response)
                reset(); // This will reset the form fields
            } else if (response?.data?.result === 'error') {
                errorToast(response?.data?.message || 'An error occurred. Please try again.');
            }
        } catch (err) {
            errorToast(err?.message || 'Failed to submit form. Please try again later.');
        } finally {
            setIsSubmitting(false); // Hide spinner
        }
    };

    const onError = (errors) => {
        const count = Object.values(errors).length;
        if (count > 0) {
            toast.error('Please fill out the correct inputs');
        }
    };

    useEffect(() => {
        if (selectResponseData?.error) {
            errorToast(selectResponseData.error);
        }
        if (selectResponseData?.data?.result === 'success') {
            successToast(selectResponseData.data?.message);
        }
    }, [selectResponseData]);




    return (<StyledListWithForm margin={margin} pt={pt} id={`${id ? id : 'ListWithForm'}`}

                                className={`list_with_form ${padding ? padding : ''} `}>
        <Container fluid style={{paddingLeft: offset +15+ 'px'}}>
            <Row>
                <Col md={6} className={'pr-0'}>
                    <ImageSocialBox img={data?.images?.list?.[0]?.full_path} title={data?.section_data?.title} description={data?.section_data?.description} showSetting={showSetting} subtitle={data?.section_data?.subtitle} />
                </Col>
                <Col className={'form-column'} md={6}>

                    <div className="form_wrapper" style={{paddingRight: offset + 'px'}}>
                        <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                            <input name={'spam_protector'} type="hidden"/>
                            <input name={'form_id'} value={'contact-form'} type="hidden"/>
                            <TextInput
                                name="name"
                                placeholder="Name*"
                                register={register}
                                validation={{
                                    required: 'Please enter your full name',

                                }}
                                error={errors.name}
                            />
                            <Row>
                                <Col md={6}>
                                    <TextInput
                                        name="email"
                                        placeholder="Email*"
                                        type="email"
                                        register={register}
                                        validation={{
                                            required: {value: true, message: 'Please enter your email'},
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Please enter a valid email address',
                                            },
                                        }}
                                        error={errors.email}
                                    />

                                </Col>
                                <Col md={6}>
                                    <TextInput
                                        name="phone"
                                        placeholder="Phone Number*"
                                        type="number"
                                        register={register}
                                        validation={{
                                            required: {value: true, message: 'Please enter your phone number'},
                                            pattern: {value: /^01[0-9]{9}$/, message: 'Please enter a valid 11-digit phone number'},
                                        }}
                                        error={errors.phone}
                                    />
                                </Col>
                            </Row>



                            <TextInput
                                name="qualification"
                                placeholder="Highest Qualification*"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Highest Qualification',
                                }}
                                error={errors.qualification}
                            />

                            <TextInput
                                name="english"
                                placeholder="English Test Details*"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter English Test Details',

                                }}
                                error={errors.english}
                            />

                            <TextInput
                                name="prefered_program"
                                placeholder="Preferred Program*"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Preferred Program',

                                }}
                                error={errors.prefered_program}
                            />

                            <TextInput
                                name="target_university"
                                placeholder="Target University*"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter Target University',
                                }}
                                error={errors.target_university}
                            />

                            <TextInput
                                name="intended_intake"
                                placeholder="Intended Intake*"
                                type="text"
                                register={register}
                                validation={{
                                    required: 'Please enter intended Intake',

                                }}
                                error={errors.intended_intake}
                            />
                            <div className="note">
                                <p>* We will not share your details with others</p>
                            </div>
                            <div className={'form-group '}>
                                <div onClick={handleSubmit(onSubmit, onError)}>
                                    <Button  src={'javascript:void(0)'} height={45} borderRadius={25} width={'210px'} text={'Submit Message'} />
                                </div>

                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    </StyledListWithForm>);
};

const StyledListWithForm = styled.div`
    box-sizing: border-box;
    position: relative;
    margin-top: -1px;
    padding-top: ${(props) => (props.pt ? props.pt + 'px' : '0')};

    .slider_component_latest{
        .global-image{
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }
    .dc-btn a {
        border: none;
    }

    .single_image_image_wrp {
        padding-top: 85% !important;

        @media (max-width: 992px) {
            padding-top: 108.772% !important;
        }
    }


    .social {
        a {
            &:hover {
                path {
                    fill: ${text} !important;
                }
            }
        }
    }

    &:after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${white};
        height: 50%;
        width: 100%;
        content: '';
        z-index: -1;

    }

    &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: ${text};
        height: 50%;
        width: 100%;
        content: '';
        z-index: -1;
    }

    .note {
        margin-bottom: 30px;

        p {
            font-size: 16px;
            line-height: 20px;
            font-width: 400;
        }
    }

    .form_wrapper {
        padding: 70px 0 70px 70px;
        height: 100%;
        position: relative;
        // overview_bg

        .left-col {
            div {
                height: 100%;
            }
        }


        form {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .textarea {
            min-height: 120px;
        }

        .title {
            font-size: 24px;
            font-weight: 4000;
            line-height: 28px;
            color: #F9F9F9;
            margin: 0 0 40px;
        }

        .form-group {
            margin-bottom: 30px;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                display: block;
            }

            .form-control {
                box-shadow: none;
                outline: 0;
                border-radius: 0;
                background: transparent;
                height: auto;
                border-left: none;
                border-right: none;
                border-top: none;
                border-bottom: 1px solid rgba(167, 161, 159, 0.7) !important;
                padding: 0 0 8px;
                color: ${text};
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: -0.16px;
                margin-bottom: 0;

                &:visited{
                    border-color: ${text};
                    border-bottom: 1px solid rgba(167, 161, 159, 1) !important;
                    
                }
                svg {
                    display: block;
                }

                &:focus {
                    border-color: ${text} !important;

                }

                &::placeholder {
                    color: rgba(167, 161, 159, 0.7);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: -0.16px;
                    
                }
            }
        }


    }

    .row {
        .form-group {
            margin-bottom: 40px !important;
        }
    }

    .form-column {
        background: ${softWhite};
    }


    .form_wrapper {
        .row {
            margin: 0;
            width: 100%;
            gap: 15px;
            justify-content: space-between;

            .col-md-6 {
                flex: 0 0 calc(50% - 15px);
                max-width: calc(50% - 15px);
                padding-left: 0;
                padding-right: 0;
            }
        }
    }


    //selector

    .filter__menu{
        border: 1px solid ${softWhite} !important;   
    }
    .filter__control {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-top: none;
        padding-left: 0;
        background: ${softWhite};
        cursor: pointer;

        .filter__value-container {
            padding-left: 0;
            padding-right: 0;
        }

        .filter__single-value {
            color: ${text};
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__placeholder {
            color: rgba(167, 161, 159, 0.7);
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.16px;
        }

        .filter__indicator-separator {
            display: none;
        }

        .filter__indicator {
            padding-right: 0;
            
            svg{
                line{
                    stroke: rgba(167, 161, 159, 0.7);
                }
            }
        }
    }

    .filter__control--is-focused {
        outline: none;
        border-bottom: 1px solid rgba(167, 161, 159, 0.7) !important;
        box-shadow: none;
    }


    @media (max-width: 992px) {
        transform: none;
        .social {
            align-items: flex-start !important;
            justify-content: flex-start !important;
        }

        .col-md-6 {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .container-fluid {
            padding-left: 0 !important;
        }

        .single_content_wrp {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }

        .form_wrapper {
            padding: 40px 20px;
            @media (max-width: 767px) {
                padding: 30px 15px !important;
            }
        }
    }
    
    @media(max-width: 767px){
        .global-image{
            border-radius: 0 !important;
        }
    }
`;

export default React.memo(ContactForm);
